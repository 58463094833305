import IoTAPI from "./api/IoTAPI";
import { axiosGet } from "./api/axiosRestAPI";

const get = (url: string) => IoTAPI.get("/co2" + url);

export const getDeviceRecent = (deviceID: string) =>
  axiosGet(get(`/recent?deviceID=${deviceID}`));

export const getChartSmartFarm = (
  smartfarmID: number,
  fromTime: string,
  toTime: string
) =>
  axiosGet(
    get(
      `/chart/smartfarm?smartfarmID=${smartfarmID}&fromTime=${fromTime}&toTime=${toTime}`
    )
  );

export const getSmartFarmRecent = (smartfarmID: number) =>
  axiosGet(get(`/smartfarm/recent?smartfarmID=${smartfarmID}`));
