import IoTAPI from "./api/IoTAPI";
import { axiosGet } from "./api/axiosRestAPI";

/**
 * NOTE 에어컨 프로퍼티
 *
 * power : boolean
 * ==> 에어컨 전원 ON/OFF
 *
 * mode : number
 * ==> 냉/난방
 *
 * temperature : number
 * ==> 에어컨 온도
 *
 * lowTemperature : number
 * ==> 현재 온도가 lowTemperature보다 낮을 경우 temperature를 heatTemperature 로 바꿈
 *
 * highTemperature : number
 * ==> 현재 온도가 highTemperature보다 높을 경우 temperature를 coolTemperature 로 바꿈
 *
 * coolTemperature : number
 * ==> 온도가 높을 시 냉방 온도
 *
 * heatTemperature : number
 * ==> 온도가 낮을 시 난방 온도
 *
 * auto : boolean
 * ==> 에어컨 자동제어 ON/OFF (원격 제어 ON/OFF 가 아님)
 */

const get = (url: string) => IoTAPI.get("/airconditioner" + url);

export const getAirConditionerInfo = (deviceID: string) =>
  axiosGet(get(`/${deviceID}/airconditioner`));
