import axios from "axios";

const IoTAPI = axios.create();

// export const baseURL = "https://iot-dev.sangsang.farm/v1"; // development
export const baseURL = "https://iot-api.sangsang.farm/v1"; // production

IoTAPI.defaults.baseURL = baseURL;

export default IoTAPI;
